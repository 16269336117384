import React, { useState } from "react";
import { RadialBarChart, RadialBar, Legend, Tooltip } from "recharts";

const RadialChartCustom = ({ data, centerText }) => {
  const [selectedChart, setSelectedChart] = useState(null);


  const handleLegendClick = (dataKey) => {
    setSelectedChart((prevSelected) =>
      prevSelected === dataKey ? null : dataKey
    );
  };

  const handleChartClick = (data) => {
    setSelectedChart((prevSelected) =>
      prevSelected === data.name ? null : data.name
    );
  };

  const processedData = data.map((item) => ({
    name: item.name,
    uv: item?.value,
    fill: item.itemStyle.color,
  }));

  const legendPayload = processedData.map((item) => ({
    value: item.name,
    type: "square",
    color: item.fill,
    id: item.name,
    className: selectedChart && selectedChart !== item.name ? "line-through" : "",
  }));

  const style = {
    bottom: -20,
    left: 30,
    lineHeight: "24px",
  };

  return (
    <div>
      <RadialBarChart
        width={300}
        height={300}
        cx={150}
        cy={120}
        innerRadius={data.length === 2? 50 : 40}
        outerRadius={data.length===2?90:100}
        barSize={20}
        data={processedData}
        onClick={(e) => {
          if (e && e.activePayload) {
            handleChartClick(e.activePayload[0].payload);
          }
        }}
      >
        {processedData.map((item, index) => (
          <RadialBar
            key={index}
            minAngle={35}
            background
            clockWise
            dataKey="uv"
            data={[item]}
            fill={item.fill}
            opacity={
              selectedChart === null || selectedChart === item.name ? 1 : 0.1
            }
          />
        ))}
        <Legend
          iconSize={10}
          width={250}
          height={100}
          layout="horizontal"
          verticalAlign="bottom"
          wrapperStyle={style}
          payload={legendPayload}
          onClick={(e) => handleLegendClick(e.id)}
          formatter={(value, entry) => (
            <span
              className={
                selectedChart && selectedChart !== entry.id ? "line-through" : ""
              }
            >
              {`${value.charAt(0).toUpperCase()}${value.slice(1)}`}
            </span>
          )}
        />
        <Tooltip />
        <text
          x={150}
          y={280}
          textAnchor="middle"
          dominantBaseline="middle"
          className="center-text"
          style={{ fill: "black", fontSize: 16 }}
        >
           {selectedChart
    ? `${selectedChart.charAt(0).toUpperCase() + selectedChart.slice(1)} min: ${processedData.find((item) => item.name === selectedChart)?.uv} min`
    : centerText.charAt(0).toUpperCase() + centerText.slice(1)}
        </text>
      </RadialBarChart>
    </div>
  );
};

export default RadialChartCustom;
