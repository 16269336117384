import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { BASE_URL } from "../config";
import Select from "react-tailwindcss-select";
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from "react-router-dom";
import fireIcon from '../../src/assets/images/fireIcon.svg'
import clockIcon from '../../src/assets/images/clockIcon.svg'
import fitbitlogo from '../../src/assets/images/fitbitlogo.svg'
import garminlogo from '../../src/assets/images/garminlogo.svg'
import whiteDownArrow from '../../src/assets/images/whiteDownArrow.svg'
import { data } from 'autoprefixer';


const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const getMonthName = (month) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const current_year = new Date().getFullYear();

  if(month == 0){
    return monthNames[11] + " " + (current_year - 1);
  }
  else if(month < 0){
    return monthNames[10] + " " + (current_year - 1);
  }else{
    return monthNames[month - 1];
  }
};

const Calendar = ({back}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentMo, setCurrentMo] = useState(new Date().getMonth() + 1);
  const [monVal,setMonVal]=useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState('FitBit');
  const [deviceStatus, setDeviceStatus] = useState('');
  // const [dayy, setDayy] = useState("THU");
  const [dayy, setDayy] = useState(() => {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDayOfMonth.getDay();
    return days[firstDay];
  });
  const [garConnect, setGarConnect] = useState(false);
  const [fitConnect, setFitConnect] = useState(false);
  const [gartokConnect, setGartokConnect] = useState(false);
  const [walkThroughPending, setWalkThroughPending] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughPending);
  const [walkThroughSteps, setWalkThroughSteps] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughSteps);
  const navigate = useNavigate();
  const handlePreviousMonth = (month) => {
    /*if (month === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      // console.log(month,currentMonth)
      // setCurrentMonth(currentMonth - 1);
      toggleOptionBox()
      setCurrentMonth(month);
      setDayy(  firstDayOfMonth(month));
    }
    */
    
    toggleOptionBox()

    if(month==0){
      setCurrentYear(new Date().getFullYear()-1);
      setCurrentMonth(12);
      setDayy(  firstDayOfMonth(month));
    }else if(month==-1){
      setCurrentYear(new Date().getFullYear()-1);
      setCurrentMonth(11);
      setDayy(  firstDayOfMonth(month));
    }else if(month==-2){
      setCurrentYear(new Date().getFullYear()-1);
      setCurrentMonth(10);
      setDayy(  firstDayOfMonth(month));
    }else{
      setCurrentMonth(month);
      setCurrentYear(new Date().getFullYear());
      setDayy(  firstDayOfMonth(month));
    }
    
    //setCurrentMonth(month);
    //setDayy(  firstDayOfMonth(month));
  };

  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handlePreviousYear = () => {
    setCurrentYear(currentYear - 1);
  };

  const handleNextYear = () => {
    setCurrentYear(currentYear + 1);
  };

  const monthDays = daysInMonth(currentMonth, currentYear);
  const firstDay = new Date(currentYear, currentMonth - 1, 1).getDay();
  const weeks = Math.ceil((monthDays + firstDay) / 7);

  const days = [];
  for (let i = 1; i <= weeks * 7; i++) {
    if (i > firstDay && i <= monthDays + firstDay) {
      days.push(i - firstDay);
    } else {
      days.push(null);
    }
  }

  const getCalendartList = (event) => {
    let selectedStartDate = moment(`${currentYear}-${currentMonth}-01`).startOf('month').format('YYYY-MM-DD');
    let selectedEndDate = moment(`${currentYear}-${currentMonth}-01`).endOf('month').format('YYYY-MM-DD');;
    setLoading(true);

    let url = '';
    if (device === "FitBit") {
      url = `${BASE_URL}/user/getfitbitexercisedata/${selectedStartDate}/${selectedEndDate}`;
    } else if (device === 'Garmin') {
      url = `${BASE_URL}/user/getgarminexercisedatabydate/${selectedStartDate}/${selectedEndDate}`;
    }

    axios({
      headers: {
        "x-access-token": localStorage.getItem('auth-token')
      },
      url,
      method: 'GET'
    }).then((res) => {
      // console.log("sdfghffghjghj",res)
      setLoading(false);
      let newData = {};
      res?.data?.data?.map((data) => {
        let date = data?.onDateTime?.split('T')[0].split('-')[2];
        newData[parseInt(date)] = data?.heartRateZones;
      });
      setCalendarEvents(newData);
    }).catch((err) => {
      setLoading(false);
    });
  };

  const getDeviceStatus = () => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/device/status`, //your url
      method: "GET",
    }).then((res) => {
      setDeviceStatus(res.data);
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    getDeviceStatus();
  }, []);

  useEffect(() => {
    setCalendarEvents([]);
    getCalendartList();
  }, [currentYear, currentMonth, device]);

  useEffect(() => {
    let isFitbitConnected = deviceStatus?.isFitbitConnected;
    let isGarminConnected = deviceStatus?.isGarminConnected;
    if (isFitbitConnected) {
      setDevice('FitBit');
    } else if (isGarminConnected) {
      setDevice('Garmin');
    } else {
      setDevice('FitBit');
    }
  }, [deviceStatus]);

  const handleChange = (value) => {
    setDevice(value);
  };
  useEffect(() => {
    let userStatus = localStorage.getItem('status');
    let subscribedStatus = localStorage.getItem('subscribedStatus');

    
    if (subscribedStatus == 'false' && userStatus != 2) {
        window.location = window.location.origin + '/membership';
        return false;
    }else if (fitConnect=='false' && garConnect =='false' ) {
      navigate('/device');
      back(0);
  } 
  
});
useEffect(() => {
  const getDeviceStatus = async () => {
    console.log("fff : ",fitConnect,garConnect)
      try {
          const res = await axios({
              headers: {
                  "x-access-token": localStorage.getItem("auth-token"),
              },
              url: `${BASE_URL}/user/device/status`,
              method: "GET",
          });
          setGarConnect(res.data.isGarminConnected);
          setFitConnect(res.data.isFitbitConnected);
          setGartokConnect(res.data.isGarminConnectedthroughtoken);
          // console.log("fff g: ",fitConnect,garConnect,res.data.isGarminConnected,res.data.isFitbitConnected)
          if (!res.data.isGarminConnected && !res.data.isFitbitConnected  ) {
           
              navigate('/device');back(0);
          } 
    
      } catch (err) {
          if (err.code === "ERR_BAD_REQUEST") {
             
          }
      }
  };

  getDeviceStatus();
}, []);



  const [isOptionBoxVisible, setIsOptionBoxVisible] = useState(false);
  const toggleOptionBox = () => {
    setIsOptionBoxVisible(!isOptionBoxVisible);
  };
  function firstDayOfMonth(currentMonth) {
    const date = new Date(new Date().getFullYear(), currentMonth - 1, 1);
    return date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
  }
  

  function printDaysOfWeek(firstDay) {
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const index = days.indexOf(firstDay);
    if (index === -1) {
      throw new Error("Invalid first day of the week");
    }
  
    const rearrangedDays = [...days.slice(index), ...days.slice(0, index)];
    return rearrangedDays.map((day, i) => {
      return (
        <div
          key={i}
          className={`bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 uppercase ${
            i === 0 ? "rounded-tl-lg" : i === 6 ? "rounded-tr-lg" : ""
          }`}
        >
          {day}
        </div>
      );
    });
  }
  return (
    <div className="justify-center md:p-5 px-3 py-5">
      <div className="bg-white md:p-4 p-0">
        {/* {(deviceStatus?.isFitbitConnected && deviceStatus?.isGarminConnected) && (
          <ul class="text-xs max-[1600px]:text-smtext-sm text-center text-gray-400 font-semibold md:divide-x md:divide-gray-200 md:rounded-lg sm:flex dark:divide-gray-700 dark:text-gray-400 py-2 list-none">
            <li class="w-full">
              <span onClick={() => handleChange('FitBit')} className={`cursor-pointer ${device === 'FitBit' && 'bg-[#FFECE7] text-black'} inline-block w-full p-4 md:rounded-lg active uppercase text-base`} aria-current="page">Fitbit</span>
            </li>
            <li class="w-full">
              <span onClick={() => handleChange('Garmin')} className={`cursor-pointer ${device === 'Garmin' && 'bg-[#FFF5F3] text-black'} inline-block w-full p-4 md:rounded-lg active uppercase text-base`}>Garmin</span>
            </li>
          </ul>
        )} */}

          {loading ? (
            <>
              <div className="hidden md:block mx-2">
                <Skeleton baseColor="#ccc" highlightColor="#fff" height={60} width={'100%'} className="mx-0 mb-4" />
              </div>
              <div className="md:hidden m-1">
                <Skeleton baseColor="#ccc" highlightColor="#fff" height={60} />
              </div>
            </>
          ) : (
        <div className="flex justify-between items-center bg-[#E53E14] rounded md:px-4 px-2 text-white w-[99.4%] mb-5">
          <div className="flex items-center justify-between md:gap-5 gap-1">
            <div className='md:text-base text-xs'>Select Device: </div>
            <div className={`py-3 px-2 xl:py-4 xl:px-6 hover:bg-[#FF481A] transition duration-700 ease-out cursor-pointer mb-1 text-center uppercase md:text-base text-xs ${fitConnect?`` : `pointer-events-none cursor-not-allowed`} ${device=='FitBit' ?`bg-[#FF481A] border-0 border-b-[2px] xl:border-b-[2px] border-solid border-white `:``}`}onClick={() => handleChange('FitBit')} ><img src={fitbitlogo} alt="Icon" className='align-middle mr-2 md:mb-0 mb-1' /> Fitbit</div>
            <div className={`py-3 px-2 xl:py-4 xl:px-6 hover:bg-[#FF481A] transition duration-700 ease-out cursor-pointer mb-1 text-center uppercase md:text-base text-xs ${garConnect ?``:`pointer-events-none cursor-not-allowed`} ${device=='Garmin' ?`bg-[#FF481A] border-0 border-b-[2px] border-solid border-white `:``}`}onClick={() => handleChange('Garmin')}><img src={garminlogo} alt="Icon" className='align-middle mr-2 md:mb-0 mb-1' /> Garmin</div>
            
          </div>
          <div className='flex items-center justify-between relative'>
            <div className='md:text-base text-xs text-center md:text-left'>
              Month: <span className='cursor-pointer ml-1 font-semibold' onClick={toggleOptionBox}>{getMonthName(currentMonth)}
                <img src={whiteDownArrow} alt="Icon" className='ml-2' />
              </span> 
            </div>
            {isOptionBoxVisible && (
            <div className='absolute bg-[#E53E14] px-5 py-4 top-10 right-0 md:w-40 w-20 flex md:gap-5 gap-2 flex-col rounded'>
              <div className='cursor-pointer hover:font-semibold md:text-base text-xs'onClick={()=>{handlePreviousMonth(currentMo)}} >{getMonthName(currentMo)}</div> 
              <div className='cursor-pointer hover:font-semibold md:text-base text-xs'onClick={()=>{handlePreviousMonth(currentMo-1)}}>{getMonthName(currentMo-1)}</div> 
              <div className='cursor-pointer hover:font-semibold md:text-base text-xs'onClick={()=>{handlePreviousMonth(currentMo-2)}}>{getMonthName(currentMo-2)}</div> 
            </div>
            )}
          </div>
        </div>
        )}

        {/* <div className="flex justify-center">
          <div className="flex justify-between my-4 w-3/4 text-gray-300 bg-gray">
            <button
              className="text-black px-4 py-2 rounded bg-transparent border-0 cursor-pointer"
              onClick={handlePreviousYear}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#000"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                />
              </svg>
            </button>
            <div
              className="text-md uppercase font-medium text-black"
              style={{ marginTop: "5px" }}
            >
              {currentYear}
            </div>
            <button
              className="text-black px-4 py-2 rounded bg-transparent border-0 cursor-pointer"
              onClick={handleNextYear}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#000"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                />
              </svg>
            </button>
          </div>
          <div className="flex justify-between my-4 w-3/4 text-gray-300 bg-gray">
            <button
              className="text-black px-4 py-2 rounded bg-transparent border-0 cursor-pointer"
              onClick={handlePreviousMonth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#000"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                />
              </svg>
            </button>
            <div
              className="text-md w-14 flex justify-center uppercase font-medium text-black"
              style={{ marginTop: "5px" }}
            >
              {getMonthName(currentMonth)}
            </div>
            <button
              className="text-black px-4 py-2 rounded bg-transparent border-0 cursor-pointer"
              onClick={handleNextMonth}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#000"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                />
              </svg>
            </button>
          </div>
        </div> */}

        {/* <div className="flex text-white rounded text-center mt-5">
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 rounded-tl-lg uppercase">Sun</div>
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 uppercase">Mon</div>
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 uppercase">Tue</div>
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 uppercase">WED</div>
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 uppercase">THR</div>
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 uppercase">Fri</div>
          <div className="bg-[#202223] w-[33.3%] md:w-[14.1867%] p-4 rounded-tr-lg uppercase">Sat</div>
        </div> */}
    <div className="flex text-white rounded text-center hidden md:flex">
      {printDaysOfWeek(dayy)}
    </div>
        <div className="flex flex-wrap">
          {days.map((day, index) => {
            let currentFullDate = `${currentYear}-${currentMonth?.toString().padStart(2, "0")}-${day?.toString().padStart(2, "0")}`;

            let isCurrentDate =
              day === new Date().getDate() &&
              currentMonth === new Date().getMonth() + 1 &&
              currentYear === new Date().getFullYear();
            let currentHeartData = {};
            calendarEvents?.[day]?.forEach(data => {
              currentHeartData[data?.heartZoneName?.toLowerCase() || 'unknown'] = data;
            });
            if (!day) {
              return '';
            }
            return (
              
              <div
                key={index}
                className={`w-[33.3%] md:w-[14.1867%] text-xs max-[1600px]:text-smtext-sm text-gray-500 text-center border border-solid border-[#3635381c] bg-[#F9F9F9] p-0 md:p-2 ${!day ? "" : ""}`}
              >
                {loading ? (
                  <>
                    <div className="hidden md:block mx-2">
                      <Skeleton baseColor="#ccc" highlightColor="#fff" height={60} width={'100%'} className="m-2" />
                      <Skeleton baseColor="#ccc" highlightColor="#fff" height={60} width={'100%'} className="m-2" />
                    </div>
                    <div className="md:hidden m-1">
                      <Skeleton baseColor="#ccc" highlightColor="#fff" height={60} />
                      <Skeleton baseColor="#ccc" highlightColor="#fff" height={60} />
                    </div>
                  </>
                ) : (
                  <div className="m-1 w-50">
                    <div className="w-full">
                      <div
                        className={`flex flex-col ${isCurrentDate
                          ? "text-white bg-[#8451dd]"
                          : "text-[#363538] font-medium"
                          } justify-center md:p-1 md:px-2 p-0 mb-2 hover:text-white hover:bg-[#9c27b0] scale-box`}
                      >
                        <div className="flex md:justify-end justify-center text-md uppercase md:hidden block bg-[#202223] text-white p-2 rounded mb-1">
                          {moment(currentFullDate).format('ddd')}
                        </div>
                        <div className="flex md:justify-end justify-center md:text-lg text-base">
                          {day}
                        </div>
                      </div>
                      <div className="border-0 border-l-4 border-solid border-[#0694F3] bg-[#C3E1F3] hover:bg-[#279fef] hover:text-white scale-box p-2 text-[#272727] mb-2">
                        <div className="flex flex-wrap justify-between rounded">
                          <div className="flex items-center gap-[4px] text-md font-medium w-[40px] xl:justify-start justify-between">
                            <img src={clockIcon} alt="Icon" /> 
                            {currentHeartData?.light?.minutes?.toFixed() || 0}
                          </div>
                          <div className="flex items-center gap-[4px] text-md font-medium w-[40px] xl:justify-start justify-between">
                            <img src={fireIcon} alt="Icon" /> 
                            {currentHeartData?.light?.caloriesOut?.toFixed() || 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="border-0 border-l-4 border-solid border-[#FF8B20] bg-[#FFD7B2] hover:bg-[#f76700] hover:text-white scale-box p-2 text-[#272727] mb-2">
                        <div className="flex flex-wrap justify-between rounded">
                          {/* <span className="hidden md:block">Vigorous</span> */}
                          <div className="flex items-center gap-[4px] text-md font-medium w-[40px] xl:justify-start justify-between">
                          <img src={clockIcon} alt="Icon" /> 
                            {currentHeartData?.moderate?.minutes?.toFixed() || 0}
                          </div>
                          <div className="flex items-center gap-[4px] text-md font-medium w-[40px] xl:justify-start justify-between">
                          <img src={fireIcon} alt="Icon" /> 
                          {currentHeartData?.moderate?.caloriesOut?.toFixed() || 0}
                          </div>
                        </div>
                      </div>
                      <div className="border-0 border-l-4 border-solid border-[#F50F51] bg-[#FFD7E2] hover:bg-[#ff2863] hover:text-white scale-box p-2 text-[#272727] mb-2">
                        <div className="flex flex-wrap justify-between rounded">
                          <div className="flex items-center gap-[4px] text-md font-medium w-[40px] xl:justify-start justify-between">
                          <img src={clockIcon} alt="Icon" /> 
                            {currentHeartData?.vigorous?.minutes?.toFixed() || 0}
                          </div>
                          <div className="flex items-center gap-[4px] text-md font-medium w-[40px] xl:justify-start justify-between">
                            <img src={fireIcon} alt="Icon" /> 
                            {currentHeartData?.vigorous?.caloriesOut?.toFixed() || 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                }
              </div>
            );
          })}
        </div>
        <div className="grow my-1 w-auto">
          <div className="flex flex-wrap justify-end items-end rounded text-black h-full md:pr-4 py-2">
            <div className="flex items-end">
              <div className="flex justify-center md:justify-start my-1 text-xs text-[#363538] max-[1600px]:text-smtext-sm mr-1 md:mr-4">
                <div className="bg-[#0694F3] scale-box mr-1 p-2 mr-2">
                </div>
                Moderate
              </div>
              <div className="flex justify-center md:justify-start my-1 text-xs text-[#363538] max-[1600px]:text-smtext-sm mr-1 md:mr-4">
                <div className="bg-[#FF8B20] scale-box 1r-2 p-2 mr-2">
                </div>
                Vigorous
              </div>
              <div className="flex justify-center md:justify-start my-1 text-xs text-[#363538] max-[1600px]:text-smtext-sm mr-1 md:mr-4">
                <div className="bg-[#F50F51] scale-box 1r-2 p-2 mr-2">
                </div>
                Peak 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Calendar;
